// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_select {
    border-radius: 10px;
    border: 1px solid black;
    padding: 8px 5px 0 5px;
    margin-left: 10px;
    font-size: 12px;
}

.select__top {
    cursor: pointer;
}
.select_opened {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 20px;
    padding: 0 5px;
}
.select_ok {
    margin-top: 5px;
}
.select_ok:last-child {
    margin-bottom: 5px;
}
.select_ok:hover {
    background-color: #eaeaea;
    cursor: pointer;
}

/* --------- DARK ---------- */

.custom_select-dark {
    border-radius: 10px;
    border: 1px solid #777;
    padding: 8px 5px 0 5px;
    margin-left: 10px;
    font-size: 12px;
	color: white;
}

.select__top-dark {
    cursor: pointer;
}
.select_opened-dark {
    position: absolute;
    background-color: #222;
    border: 1px solid #777;
    border-radius: 5px;
    margin-top: 20px;
    padding: 0 5px;
}
.select_ok-dark {
    margin-top: 5px;
}
.select_ok-dark:last-child {
    margin-bottom: 5px;
}
.select_ok-dark:hover {
    background-color: #333;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/patologies/components/CustomSelect/CustomSelect.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA,8BAA8B;;AAE9B;IACI,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;CAClB,YAAY;AACb;;AAEA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".custom_select {\n    border-radius: 10px;\n    border: 1px solid black;\n    padding: 8px 5px 0 5px;\n    margin-left: 10px;\n    font-size: 12px;\n}\n\n.select__top {\n    cursor: pointer;\n}\n.select_opened {\n    position: absolute;\n    background-color: white;\n    border: 1px solid black;\n    border-radius: 5px;\n    margin-top: 20px;\n    padding: 0 5px;\n}\n.select_ok {\n    margin-top: 5px;\n}\n.select_ok:last-child {\n    margin-bottom: 5px;\n}\n.select_ok:hover {\n    background-color: #eaeaea;\n    cursor: pointer;\n}\n\n/* --------- DARK ---------- */\n\n.custom_select-dark {\n    border-radius: 10px;\n    border: 1px solid #777;\n    padding: 8px 5px 0 5px;\n    margin-left: 10px;\n    font-size: 12px;\n\tcolor: white;\n}\n\n.select__top-dark {\n    cursor: pointer;\n}\n.select_opened-dark {\n    position: absolute;\n    background-color: #222;\n    border: 1px solid #777;\n    border-radius: 5px;\n    margin-top: 20px;\n    padding: 0 5px;\n}\n.select_ok-dark {\n    margin-top: 5px;\n}\n.select_ok-dark:last-child {\n    margin-bottom: 5px;\n}\n.select_ok-dark:hover {\n    background-color: #333;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
