import React from 'react';

type Props = {
    className: string;
}

const IconImgAdd = ({ className }: Props) => {
  return (
    <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_35_584)">
        <path d="M11.0832 4.66663V1.16663M9.33317 2.91663H12.8332M12.8332 6.99996V10.0333C12.8332 11.0134 12.8332 11.5034 12.6424 11.8778C12.4747 12.2071 12.2069 12.4748 11.8777 12.6426C11.5033 12.8333 11.0133 12.8333 10.0332 12.8333H3.9665C2.98641 12.8333 2.49637 12.8333 2.12202 12.6426C1.79274 12.4748 1.52502 12.2071 1.35724 11.8778C1.1665 11.5034 1.1665 11.0134 1.1665 10.0333V3.96663C1.1665 2.98653 1.1665 2.49649 1.35724 2.12214C1.52502 1.79286 1.79274 1.52514 2.12202 1.35736C2.49637 1.16663 2.98641 1.16663 3.9665 1.16663H6.99984M1.25152 11.6236C1.52519 10.6391 2.42814 9.91663 3.49984 9.91663H7.58317C8.12527 9.91663 8.39632 9.91663 8.62171 9.96146C9.54733 10.1456 10.2709 10.8691 10.455 11.7947C10.4998 12.0201 10.4998 12.2912 10.4998 12.8333M8.1665 5.54163C8.1665 6.83029 7.12183 7.87496 5.83317 7.87496C4.54451 7.87496 3.49984 6.83029 3.49984 5.54163C3.49984 4.25296 4.54451 3.20829 5.83317 3.20829C7.12183 3.20829 8.1665 4.25296 8.1665 5.54163Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_35_584">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  );
};

export default IconImgAdd;
