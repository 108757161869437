// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    border: 1px solid #AFAFAF;
    border-radius: 15px;
    height: 32px;
    width: 175px;
    font-size: 12px;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    color: #9E9E9E;
}
`, "",{"version":3,"sources":["webpack://./src/components/inputs/Input.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["input {\n    border: 1px solid #AFAFAF;\n    border-radius: 15px;\n    height: 32px;\n    width: 175px;\n    font-size: 12px;\n    outline: none;\n    padding-left: 15px;\n    padding-right: 15px;\n    color: #9E9E9E;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
