// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__page_reg {
    display: flex;
    justify-content: center;
    margin-top: 30vh;
}

.border__page_reg {
    background-color: white;
    border: 1px #DBDBDB solid;
    border-radius: 15px;
    width: 250px;
    height: 411px;
}

.header__reg {
    color: #606060;
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 80px;
}

#up_inp_reg {
    margin-bottom: 12px;
}

#down_inp_reg {
    margin-bottom: 12px;
}

#sub_links_reg {
    display: flex;
    padding: 10px 0 20px 0;
}

#sign_up_a_reg {
    text-align: right;
    width: 204px;
    color: #7E7E7E;
    font-size: 10px;
    text-decoration: underline;
}

#inputs_reg {
    margin-bottom: 15px;
}

#login__button_reg {
    display: flex;
    justify-content: center;
}

.button_register {
    padding: 10px;
    width: 200px;

    font-size: 10px;
    font-weight: 1000;

    border-radius: 40px;
    outline: none;
    border: 0px;
    background-color: #4F5FEB;
    color: white;
}
.button_register:hover {
    background-color: #3f4cbf;
}
.input__reg {
    margin-left: 20px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/register/Register.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,YAAY;;IAEZ,eAAe;IACf,iBAAiB;;IAEjB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".login__page_reg {\n    display: flex;\n    justify-content: center;\n    margin-top: 30vh;\n}\n\n.border__page_reg {\n    background-color: white;\n    border: 1px #DBDBDB solid;\n    border-radius: 15px;\n    width: 250px;\n    height: 411px;\n}\n\n.header__reg {\n    color: #606060;\n    font-size: 24px;\n    text-align: center;\n    margin-top: 30px;\n    margin-bottom: 80px;\n}\n\n#up_inp_reg {\n    margin-bottom: 12px;\n}\n\n#down_inp_reg {\n    margin-bottom: 12px;\n}\n\n#sub_links_reg {\n    display: flex;\n    padding: 10px 0 20px 0;\n}\n\n#sign_up_a_reg {\n    text-align: right;\n    width: 204px;\n    color: #7E7E7E;\n    font-size: 10px;\n    text-decoration: underline;\n}\n\n#inputs_reg {\n    margin-bottom: 15px;\n}\n\n#login__button_reg {\n    display: flex;\n    justify-content: center;\n}\n\n.button_register {\n    padding: 10px;\n    width: 200px;\n\n    font-size: 10px;\n    font-weight: 1000;\n\n    border-radius: 40px;\n    outline: none;\n    border: 0px;\n    background-color: #4F5FEB;\n    color: white;\n}\n.button_register:hover {\n    background-color: #3f4cbf;\n}\n.input__reg {\n    margin-left: 20px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
